<template>
	<div class="container">
		<div class="title-wrap">
			<h2 class="title">{{ boardName }}</h2> <el-button type="primary" @click="$router.push('/board/'+$route.params.id)" style="display: inline-block; float: right;">목록으로</el-button>
		</div>
			<el-input placeholder="제목" v-model="title" clearable> </el-input>

    <froala :tag="'textarea'" :config="config" v-model="content"></froala>
    <br/>
    <p class="error">{{editErrorText}}</p>
    <div style="float: right; display: inline-block;">
      <el-button type="success" :disabled="!editLoad" @click="edit">{{editButtonText}}</el-button>
    </div> 
    
	</div>
</template>

<script>
import axios from "axios";
import {apiUrl} from '../constants/config';
import { Loading } from 'element-ui';


var loadingInstance;


export default {
	data() {
		return {
      editLoad: true,
			editButtonText: "수정",
			editErrorText: "",
			title: "",
      content: "",
      boardName: "",
      config: {
      key: "wFE7nE5F4B3C2B10A5A5fvusH-8E2pd1efxE2F2G2G1B10B2C2E6C1B1==",
      heightMin: 300,
      heightMax: 500,
      language: 'ko',
      imageUploadURL: apiUrl + "/article/uploadImage",
      imageUploadMethod: 'POST',
      imageMaxSize: 100 * 1024 * 1024,
      imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
      toolbarButtons: [['bold', 'italic', 'underline', 'strikeThrough', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor'], ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR'], ['undo', 'redo', 'fullscreen', 'html']]
      }
		}
	},
	methods: {
    edit(){
      this.editLoad = false;
      this.editButtonText = "수정 중";
      this.editErrorText = "";

      if(this.title == ""){
        this.editLoad = true;
        this.editButtonText = "수정";
        this.editErrorText = "제목을 입력해주세요.";
        return;
      }

      if(this.content == ""){
        this.editLoad = true;
        this.editButtonText = "수정";
        this.editErrorText = "내용을 입력해주세요.";
        return;
      }

      var vm = this;
      axios.post(apiUrl+'/article/'+this.$route.params.idx+'/edit', {title: vm.title, content: vm.content}, { withCredentials: true })
    .then(function(response) {
      if(response.data.success){
        vm.$swal.fire(
						'게시글을 수정했습니다.',
						"게시판에서 게시글을 조회하실 수 있습니다.",
						'success'
					).then(() => {
						vm.$router.push("/board/"+vm.$route.params.id);
					})
      }
      else{
        vm.editErrorText = response.data.message;
        vm.editLoad = true;
        vm.editButtonText = "수정";
        return;
      }
    })
    .catch(function(error) {
      console.log(error);
    });

    }

	},
	mounted(){

},
  beforeCreate(){
    loadingInstance = Loading.service({ fullscreen: true });

    var vm = this;
    axios.get(apiUrl+'/board/'+this.$route.params.id+'/', { withCredentials: true })
    .then(function(response) {
      if(response.data.success){
        vm.boardName = response.data.boardName;
      }
      else{
      vm.$router.push("/");
      }
    })
    .catch(function(error) {
      console.log(error);
    });

    axios.get(apiUrl+'/article/'+this.$route.params.idx, { withCredentials: true })
    .then(function(response) {
      if(response.data.success && response.data.canEdit){
        vm.title = response.data.title;
        vm.content = response.data.content;
        loadingInstance.close();
      }
    })
    .catch(function(error) {
      console.log(error);
    });

  },

}
</script>

<style scoped>

.box-card {
  margin-bottom: 15px;
  cursor: pointer;
}

.error {
  display: inline-block;
  color: red;
  font-size: 14px;
}

</style>
